<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <!-- eslint-disable @stylistic/max-len -->
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.2222 12H8.88889V13.3333H8V10.6667H10.2222V10.4889C10.2222 10.3275 10.1815 10.158 10.1026 10C9.99245 9.77959 9.80788 9.5817 9.55556 9.45985C9.39112 9.38045 9.19791 9.33333 8.97778 9.33333H7.82222C7.11111 9.33333 6.66667 9.95556 6.66667 10.5778V13.5111C6.66667 14.0444 7.11111 14.6667 7.82222 14.6667H9.06667C9.24794 14.6667 9.41188 14.632 9.55556 14.5701C9.81877 14.4566 10.0139 14.2515 10.1229 14C10.1879 13.8503 10.2222 13.6841 10.2222 13.5111V12ZM11.5556 9.33333V14.6667H12.8889V9.33333H11.5556ZM16.8889 11.5556H15.5556V10.6667H16.8889V9.33333H14.2222V14.6667H15.5556V12.8889H16.8889V11.5556ZM5.77778 2H18.2222C20.3046 2 22 3.69543 22 5.77778V18.2222C22 20.3046 20.3046 22 18.2222 22H5.77778C3.69543 22 2 20.3046 2 18.2222V5.77778C2 3.69543 3.69543 2 5.77778 2ZM5.77778 4H18.2222C19.2 4 20 4.8 20 5.77778V18.2222C20 19.2 19.2 20 18.2222 20H5.77778C4.8 20 4 19.2 4 18.2222V5.77778C4 4.8 4.8 4 5.77778 4Z"
                :fill="color"
            />
            <!-- eslint-enable @stylistic/max-len -->
        </template>
    </AppIcon>
</template>
